import React from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import PrimaryNavbar from './PrimaryNavbar'
// Styled-components styles
const Container = styled.div`
  height: auto;
  // width: 90%;

  @media(min-width: 786px) {
    width: 100%;
  }
`;

const PageStyles = styled.div`
    // padding:60px 0;
    background-color: #141414;
    min-height: 100vh;
`;

export const Copyright = styled.div`
position:relative;
display: flex;
justify-content: center;
position: fixed;
bottom: 0;
width:100%;
border-top:1px solid white;
padding:12px;
  p{
   
    font-size: 12px;
    text-transform: uppercase; 
    color:white;
    }

`;


const PublicLayout = (props) => {

  return (
    <PageStyles>
      <Container>
        <PrimaryNavbar />
        <Row noGutters>
          {props.children}
        </Row>
        {/* <Copyright>
          <p> &#169; Copyright 2021 Easyweb </p>
        </Copyright> */}
      </Container>
    </PageStyles>
  )
}

export default PublicLayout;
