import React from 'react';
// import LogoIcon from '../../assets/newLogo.png'
import LogoIcon from '../../assets/easy-web.svg'
import { Navbar } from 'react-bootstrap';

export default function PrimaryNavbar(props) {
  return (
    <>
      <Navbar style={{ background: "#141414",padding:"15px" }}>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={LogoIcon}
            width="129px"
            className="d-inline-block align-top logo-image"
          />{' '}
    </Navbar.Brand>
      </Navbar>
    </>
  );
}