import react, { createContext, useContext } from "react";

export const StateContext = createContext();

export const useStateVariable = () => useContext(StateContext);

export default class ContextProvider extends react.Component {
    state=JSON.parse(localStorage.getItem("loginDetails")) ?? {
        authenticated:false,
        userId:null
    }
    updateContext=(updatedValue)=>{
        // console.log('here', updatedValue)
        this.setState({...updatedValue});
        let item = JSON.parse(localStorage.getItem("loginDetails"));
        item ={...item, ...updatedValue};
    
        localStorage.setItem("loginDetails", JSON.stringify(item));
    }
    render(){
        return(
            <StateContext.Provider value={{...this.state, updateContext:this.updateContext}} >
                {this.props.children}
                </StateContext.Provider>
        )
    }
}