import axios from "axios";
import React from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from "react-router-dom";
// const JSON_HEADER = { headers: { "Content-Type": "application/json" } };
// const MULTI_PART_HEADER = { headers: { 'Content-Type': 'multipart/form-data' } };


const errorHandler = (err) => {
  const message = err?.message;
  return Promise.reject(message ? typeof message != 'string' ? JSON.stringify(message) : message : JSON.stringify(err))
};

const dataHandler = (promise) => promise.then(res => res?.data).catch(errorHandler);



//let history = useHistory();
class HttpService {

  setProps=(pop)=>{
    this.props=pop;
  }
 dataHandler1 = (promise) => promise.then(res => res?.data).catch(this.errorHandler1);

   errorHandler1 = (err) => {
    const message = err?.response?.data?.ErrorModel?.errorMessage ? err?.response?.data?.ErrorModel?.errorMessage : 'Noe gikk galt. Prøv igjen senere.'
    confirmAlert({
      title: 'Error',
      message: message,
      buttons: [
        {
          label: 'Ok',
          // onClick: this.handleRedirect
        },
  
      ]
    });
    if (err.response.status === 401) {
      //place your reentry code
      // pHistory.history.push("/login");
      
      //localStorage.removeItem("loginDetails");
      // let baseUrl=window.location.origin;
      // return window.location.href = baseUrl+"/login";
      localStorage.removeItem("loginDetails");
          let baseUrl=window.location.origin;
          return window.location.href = baseUrl+"/login";
    
     }
    return Promise.reject(err)
  };
  // baseURL = process.env.REACT_APP_KPI_API;
  baseURL="https://api.easyweb.no/api/v1/"
  getusers = () => dataHandler(axios.get(this.baseURL + `users`));
 

  loginCall = (postData) => {
    return axios.post(
      `${this.baseURL}admin/login`, postData
    )
      .then(res => {
        sessionStorage.setItem("loginDetails", JSON.stringify(res?.data));
        return Promise.resolve(res?.data)

      })
      .catch(err => {
        return Promise.reject(err)
      });

  }

  resetPassword = (postData) => {
    return axios.post(
      `${this.baseURL}reset/password`, postData
    )
      .then(res => {
        return Promise.resolve(res?.data)
      })
      .catch(err => {
        return Promise.reject(err)
      });

  }

  //google auth

  getGoogleAuthInfo = () => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }

    return axios.get(
      `${this.baseURL}google/auth/token`,
      header
    )
      .then(res => {
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }
  //users
  getUsersList = () => {
   const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }
  return this.dataHandler1(axios.get(`${this.baseURL}admin/user/list`,header));
    // return axios.get(
    //   `${this.baseURL}admin/user/list`,
    //   header
    // )
    //   .then(res => {
    //     return Promise.resolve(res?.data)

    //   })
    //   .catch(err => {
    //     const message = err?.response?.data?.ErrorModel?.errorMessage ? err?.response?.data?.ErrorModel?.errorMessage : 'Noe gikk galt. Prøv igjen senere.'
    //     confirmAlert({
    //       title: 'Error',
    //       message: message,
    //       buttons: [
    //         {
    //           label: 'Ok',
    //           // onClick: this.handleRedirect
    //         },

    //       ]
    //     });
    //     if (err.response.status === 401) {
    //       //place your reentry code
    //       // pHistory.history.push("/login");
          
    //       localStorage.removeItem("loginDetails");
    //       let baseUrl=window.location.origin;
    //       this.props.history.push("/login");
    //       //return window.location.href = baseUrl+"/login";
          
    //      }
    //     return Promise.reject(err)
    //   });

  }

  createUser = (postData) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.post(
      `${this.baseURL}admin/user/create`, postData, header
    )
      .then(res => {
        confirmAlert({
          title: 'Vellykket',
          message: res.data.message || "Create successful!",
          buttons: [
            {
              label: 'Ok',
            },

          ]
        });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }
  updateUser = (id, putData) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.patch(
      `${this.baseURL}admin/user/${id}`, putData, header
    )
      .then(res => {
        confirmAlert({
          title: 'Vellykket',
          message: res.data.message || "Update successful!",
          buttons: [
            {
              label: 'Ok',
            },

          ]
        });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }

  reInviteUser = (id) => {
    const token = JSON.parse(localStorage.getItem("loginDetails"))?.accessToken
    var config = {
      method: 'patch',
      url: `${this.baseURL}admin/user/reinvite/${id}`,
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    };
    return axios(config)
  .then(function (res) {
    confirmAlert({
      title: 'Vellykket',
      message: res.data.message || "Invite successful!",
      buttons: [
        {
          label: 'Ok',
        },

      ]
    });
    return Promise.resolve(res?.data)
}) .catch(this.errorHandler1);
  }

  deleteUser = (id) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.delete(
      `${this.baseURL}admin/user/${id}`, header
    )
      .then(res => {
        confirmAlert({
          title: 'Vellykket',
          message: res.data.message || "Delete successful!",
          buttons: [
            {
              label: 'Ok',
            },

          ]
        });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);
  }
  //sites
  getSitesList = (id) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.get(
      `${this.baseURL}admin/site/list/${id}`,
      header
    )
      .then(res => {
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);
  }
  getSiteDetails = (id) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.get(
      `${this.baseURL}admin/site/${id}`,
      header
    )
      .then(res => {
        return Promise.resolve(res?.data)
      })
      .catch(this.errorHandler1);

  }

  createSite = (postData) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.post(
      `${this.baseURL}admin/site/create`, postData, header
    )
      .then(res => {
        confirmAlert({
            title: 'Vellykket',
            message: res.data.message || "Site created successfully!",
            buttons: [
              {
                label: 'Ok',
              },
  
            ]
          });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);
  }
  updateSite = (id, putData) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.patch(
      `${this.baseURL}admin/site/edit/${id}`, putData, header
    )
      .then(res => {
        confirmAlert({
          title: 'Vellykket',
          message: res.data.message || "Site updated successfully!",
          buttons: [
            {
              label: 'Ok',
            },

          ]
        });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }
  deleteSite = (id) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.delete(
      `${this.baseURL}admin/site/${id}`, header
    )
      .then(res => {
        confirmAlert({
          title: 'Vellykket',
          message: res.data.message || "Site deleted successfully!",
          buttons: [
            {
              label: 'Ok',
            },

          ]
        });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }
  deleteSiteEntry = (id) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.delete(
      `${this.baseURL}admin/site/metric/${id}`, header
    )
      .then(res => {
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }
  deleteSourceEntry = (id) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.delete(
      `${this.baseURL}admin/site/source/${id}`, header
    )
      .then(res => {
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }
  //new ones
  getAllSitesList = () => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.get(
      `${this.baseURL}admin/site/list`,
      header
    )
      .then(res => {
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }
  addSite = (postData) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.post(
      `${this.baseURL}admin/user/site/add`, postData, header
    )
      .then(res => {
        confirmAlert({
            title: 'Vellykket',
            message: res.data.message || "Site Added successfully!",
            buttons: [
              {
                label: 'Ok',
              },
  
            ]
          });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }
  removeSite = (id) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.delete(
      `${this.baseURL}admin/user/site/${id}`, header
    )
      .then(res => {
        confirmAlert({
            title: 'Vellykket',
            message: res.data.message || "Site removed successfully!",
            buttons: [
              {
                label: 'Ok',
              },
  
            ]
          });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }
  setDefaultSite = (postData) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.patch(
      `${this.baseURL}admin/user/site/default`,postData, header
    )
      .then(res => {
        confirmAlert({
            title: 'Vellykket',
            message: res.data.message || "Site set to default successfully!",
            buttons: [
              {
                label: 'Ok',
              },
  
            ]
          });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }
  //general settings
  getGenSettings = () => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.get(
      `${this.baseURL}admin/settings/general`, header
    )
      .then(res => {
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }

  updategenSettings = (putData) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.patch(
      `${this.baseURL}admin/settings/general`, putData, header
    )
      .then(res => {
        confirmAlert({
          title: 'Vellykket',
          message: res.data.message || "Update successful!",
          buttons: [
            {
              label: 'Ok',
            },

          ]
        });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }
  //text settings
  getTextSettings = () => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.get(
      `${this.baseURL}admin/settings/text`, header
    )
      .then(res => {
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }

  updateTextSettings = (putData) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.patch(
      `${this.baseURL}admin/settings/text`, putData, header
    )
      .then(res => {
        confirmAlert({
          title: 'Vellykket',
          message: res.data.message || "Update successful!",
          buttons: [
            {
              label: 'Ok',
            },

          ]
        });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }

//additional services

getAdditionalServicesList = () => {
  const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
 const header = {
   headers: {
     Authorization: `Bearer ${token}`,
   }
 }
   return axios.get(
     `${this.baseURL}/additional_services`,
     header
   )
     .then(res => {
       return Promise.resolve(res?.data)

     })
     .catch(this.errorHandler1);

 }
  
  createAdditionalServices = (postData) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.post(
      `${this.baseURL}admin/additional_service/create`, postData, header
    )
      .then(res => {
        confirmAlert({
          title: 'Vellykket',
          message: res.data.message || "Create successful!",
          buttons: [
            {
              label: 'Ok',
            },

          ]
        });
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);
  }
  getAdditionalServices = (id) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.get(
      `${this.baseURL}admin/additional_service/${id}`,
      header
    )
      .then(res => {
        return Promise.resolve(res?.data)
      })
      .catch(this.errorHandler1);

  }
  updateAdditionalServices = (id, putData) => {
    const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.patch(
      `${this.baseURL}admin/additional_service/${id}`, putData, header
    )
      .then(res => {
        confirmAlert({
          title: 'Vellykket',
          message: res.data.message || "Update successful!",
          buttons: [
            {
              label: 'Ok',
            },

          ]
        });
        
        return Promise.resolve(res?.data)

      })
      .catch(this.errorHandler1);

  }

deleteAdditionalServices = (id) => {
  const token = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }
  return axios.delete(
    `${this.baseURL}admin/additional_service/${id}`, header
  )
    .then(res => {
      confirmAlert({
        title: 'Vellykket',
        message: res.data.message || "Delete successful!",
        buttons: [
          {
            label: 'Ok',
          },

        ]
      });
      return Promise.resolve(res?.data)

    })
    .catch(this.errorHandler1);

}

}



export default  new HttpService()
