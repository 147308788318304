import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContextProvider from './context/state'
// import PublicRoutes from './public/PublicRoutes'
import PrivateRoutes from './private/PrivateRoutes'
import {
  useLocation
} from "react-router-dom";
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
function App() {
// console.log('session', JSON.parse(sessionStorage.getItem("USER"))?.authenticated)

  // const isLoggedIn = JSON.parse(sessionStorage.getItem("loginDetails"))?.accessToken || false
  const { pathname } = useLocation();
  console.log("pathname",pathname);
  return (
    <div className="App">
     <ContextProvider>
     <PrivateRoutes pathname={pathname}  />
      {/* {isLoggedIn ? <PrivateRoutes /> : <PublicRoutes />} */}
      </ContextProvider>
     
    </div>
  );
}

export default App;
