import React, { useState } from "react";
import styled from 'styled-components';
import { Formik, Form, Field as FormikField, ErrorMessage } from 'formik';
import PublicLayout from '../layouts/PublicLayout'
import * as Yup from 'yup';
import HttpService from "../../services/HttpService"
import { withRouter } from 'react-router'
function ResetPasswordPage(props) {
    const [message, setMessage] = useState("")
    return (
        <PublicLayout>
            <Formik
                initialValues={{
                    newPassword: "", confirmPassword: "",
                    token: props.match.params.token
                }}
                validationSchema={Yup.object().shape({
                    newPassword: Yup.string()
                        .required("Skriv inn nytt passord")
                        .min(8, 'Passordet skal inneholde minst 8 tegn'),
                    confirmPassword: Yup.string()
                        .required("Bekreft nytt passord")
                        .oneOf([Yup.ref('newPassword'), null], 'Passordene må være like'),

                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    // console.log('values', values)
                    try {
                        const response = await HttpService.resetPassword(values);
                        response && setMessage("Passordet er endret. Du kan gå tilbake til appen for å logge inn.")
                    }
                    catch (err) {
                        const message = err?.response?.data?.ErrorModel?.errorMessage ? err?.response?.data?.ErrorModel?.errorMessage : 'Noe gikk galt. Prøv igjen senere.'
                        setMessage(message)
                    }

                    resetForm();
                    setSubmitting(false);
                }}
            >
                {
                    ({
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <LoginWrapper className="mx-auto">
                            <Form onSubmit={handleSubmit}
                                className="login-container"
                                style={{
                                    textAlign: "left",
                                    // fontFamily:"Suisse Int'l"
                                }}>
                                <div style={{ marginBottom: "15px" }}>
                                    <label style={{ fontFamily: "arial" }}>Nytt passord</label>
                                    <br />
                                    <Field
                                        name="newPassword"
                                        type="password"
                                    />
                                    <ErrorMessage name="newPassword" component="div" className="errorStylesGreen" />
                                </div>
                                <div style={{ marginBottom: "15px" }}>
                                    <label style={{ fontFamily: "arial" }}>Bekreft passord</label>
                                    <br />
                                    <Field
                                        name="confirmPassword"
                                        type="password"
                                    />
                                    <ErrorMessage name="confirmPassword" component="div" className="errorStylesGreen" />
                                </div>
                                <Button type="submit" disabled={isSubmitting}  style={{
                                    color:"#000000"
                                }}>
                                    ENDRE PASSORD
                                </Button>

                            </Form>
                            <div style={{ fontFamily: "arial", marginTop: "10px", padding: "0px 15px", maxWidth:"515px"}} className="errorStylesGreen">{
                                message && <center>{message}</center>
                            }</div>
                        </LoginWrapper>
                    )
                }

            </Formik>
        </PublicLayout>
    );
}

export default withRouter(ResetPasswordPage);

const Button = styled.button`
color: #000;
background:#B8FFC8;
border:1px solid #00FF85;
padding:5px;
margin:15px auto 5px;
font-family:arial;
font-size:16px;
// width:350px;
// height:40px;
width: 100%;
max-width:516px;
height:50px;
font-weight:500
@media (max-width:500px) {
    width: 100%;
  }
  @media (min-width:1600px) {
    width:516px;
    height:50px;
    font-weight:500
   }
`
const Field = styled(FormikField)`
// width:350px;
// margin:10px auto 5px;
padding:5px;
font-family:arial;
width:516px;
height:50px;
padding:10px;
@media (max-width:500px) {
    width: 100%;
  }
@media (min-width:1600px) {
width:516px;
height:50px;
padding:10px;
}
`
const LoginWrapper = styled.div`
color: white;
min-width: 360px;
line-height:19px;
font-size:14px;
@media (max-width:500px) {
    width: 100%;
  }
@media (min-width:1600px) {
   font-size:14px;
   line-height:19px;
}
`