import React, { Suspense, lazy } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";
import { Spinner } from "react-bootstrap"

import LoginPage from '../public/pages/LoginPage';
import ResetPasswordPage from '../public/pages/ResetPasswordPage'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const UsersPage = lazy(() => import('./pages/Users'))
const AdditionalServicesPage = lazy(() => import('./pages/AdditionalServices'))
const SitesPage = lazy(() => import('./pages/Sites'))
const GeneralSettingsPage = lazy(() => import('./pages/GeneralSettingsPage'))
const TextSettingsPage = lazy(() => import('./pages/TextSettings'))
const UsersCreatePage = lazy(() => import('../components/users/CreateUser'))
const UsersEditPage = lazy(() => import('../components/users/UpdateUser'))
const CreateSitePage = lazy(() => import('../components/sites/CreateSite'))
const UpdateSitePage = lazy(() => import('../components/sites/UpdateSite'))
const GoogleAuthPage = lazy(() => import('./pages/googleAuth'))



const isAuthenticated = JSON.parse(localStorage.getItem("loginDetails"))?.accessToken || false;
const Routes = (props) => {
    let location  = props.pathname;
    let history  = props.history;
    console.log("location",props.pathname);
    return (
        <Router>
            <Suspense fallback={
                <div style={{ marginTop: "10%" }}>
                    <Spinner animation="border" role="status" size="lg">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            }>
                <Switch>
                    
                    <Route exact path="/">
                    {isAuthenticated ? (
                        <Redirect to='/users'  />
                    
                    ):
                    <Redirect to='/login'  />
                    }
                    </Route>
                    <Route exact path="/login" >
                  
                        <LoginPage locationpath={location} history={history} />
                    
                    </Route>
                    <Route exact path="/reset-password/:token">
                        <ResetPasswordPage />
                    </Route>
                    {/* <Route exact path="/">
                        <UsersPage />
                    </Route> */}
                    <Route exact path="/users">
                        <UsersPage />
                    </Route>
                    <Route exact path="/generalSettings">
                        <GeneralSettingsPage />
                    </Route>
                    <Route exact path="/textSettings">
                        <TextSettingsPage />
                    </Route>
                    <Route exact path="/users/create">
                        <UsersCreatePage />
                    </Route>
                    <Route exact path="/users/:id/edit">
                        <UsersEditPage />
                    </Route>
                    <Route exact path="/sites/create">
                        <CreateSitePage />
                    </Route>
                    <Route exact path="/sites">
                        <SitesPage />
                    </Route>
                    <Route exact path="/sites/:id/edit">
                        <UpdateSitePage />
                    </Route>
                    <Route exact path="/googleAuth">
                        <GoogleAuthPage />
                    </Route>
                    <Route exact path="/additionalServices">
                        <AdditionalServicesPage />
                    </Route>
                </Switch>
            </Suspense>
        </Router>
    )
}

const PrivateRoutes = (props) => {
console.log("props",props);

    return (
                <Routes pathname={props.pathname} history={props.history}/>
        
    )
}

export default PrivateRoutes;