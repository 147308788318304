import React, { useState } from "react";
import styled from 'styled-components';
import { Formik, Form, Field as FormikField, ErrorMessage } from 'formik';
import PublicLayout from '../layouts/PublicLayout'
import * as Yup from 'yup';
import HttpService from "../../services/HttpService"
import { useStateVariable, StateContext } from "../../context/state";
import { useHistory,useLocation } from "react-router-dom";
import { withRouter } from 'react-router'

function LoginPage(props) {
    console.log("props",props);
    const { pathname } = useLocation();
    const [message, setMessage] = useState("")
    const contextData = useStateVariable(StateContext);

    return (
        <PublicLayout>
            <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email("Skriv inn en gyldig e-postadresse")
                        .required("Fyll ut din e-postadresse"),
                    password: Yup.string()
                        .required("Fyll ut passordet ditt"),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    try {
                        const response = await HttpService.loginCall(values);
                        contextData.updateContext({
                            ...response
                        })
                        console.log("success",response);
                        console.log("props loc",props);
                        let  redirectTo  = props.locationpath;
                        //  
                        console.log("redirectTo",redirectTo);
                        console.log("pathname",pathname);
                       if(redirectTo==pathname||redirectTo=='/login'||redirectTo=='/'){
                           redirectTo="/users"
                       }
                        response && props.history.push(redirectTo == null ? "/users" :  redirectTo);
                    }
                    catch (err) {
                        const message = err?.response?.data?.ErrorModel?.errorMessage ? err?.response?.data?.ErrorModel?.errorMessage : 'Noe gikk galt. Prøv igjen senere.'
                        setMessage(message)
                    }

                    resetForm();
                    setSubmitting(false);
                }}
            >
                {
                    ({
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <LoginWrapper className="mx-auto">
                            <Form onSubmit={handleSubmit}
                                className="login-container"
                                style={{
                                    textAlign: "left",
                                    // fontFamily:"Suisse Int'l"
                                }}>
                                <div style={{ marginBottom: "15px" }}>
                                    <label style={{ fontFamily: "arial" }}>Brukernavn</label>
                                    <br />
                                    <Field
                                        name="email"
                                        type="text"
                                    />
                                    <ErrorMessage name="email" component="div" className="errorStylesGreen" />
                                </div>
                                <div style={{ marginBottom: "15px" }}>
                                    <label style={{ fontFamily: "arial" }}>Passord</label>
                                    <br />
                                    <Field
                                        name="password"
                                        type="password"
                                    />
                                    <ErrorMessage name="password" component="div" className="errorStylesGreen" />
                                </div>
                                <Button type="submit" disabled={isSubmitting} style={{
                                    color:"#000000"
                                }}>
                                    LOGG INN
                                </Button>
                            </Form>
                            <div style={{ fontFamily: "arial", marginTop: "10px", padding: "0px 15px", maxWidth:"515px"}} className="errorStylesGreen">{
                                message && <center>{message}</center>
                            }</div>
                        </LoginWrapper>
                    )
                }

            </Formik>
        </PublicLayout>
    );
}

export default withRouter(LoginPage);

const Button = styled.button`

background:#B8FFC8;
border:1px solid #00FF85;
padding:5px;
margin:15px auto 5px;
font-family:arial;
font-size:16px;
// width:350px;
// height:40px;
width:100%;
max-width:516px;
height:50px;
font-weight:500
@media (max-width:500px) {
    width: 100%;
  }
  @media (min-width:1600px) {
    width:516px;
    height:50px;
    font-weight:500
   }
`
const Field = styled(FormikField)`
// width:350px;
// margin:10px auto 5px;
padding:5px;
font-family:arial;
width:516px;
height:50px;
padding:10px;
@media (max-width:500px) {
    width: 100%;
  }
@media (min-width:1600px) {
width:516px;
height:50px;
padding:10px;
}
`
const LoginWrapper = styled.div`
color: white;
min-width: 360px;
line-height:19px;
font-size:14px;
@media (max-width:500px) {
    width: 100%;
  }
@media (min-width:1600px) {
   font-size:14px;
   line-height:19px;
}
`